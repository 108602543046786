import {useEffect, useRef} from 'react';

export const useIsMounted = (): boolean => {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;

    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return isComponentMounted.current;
};
