// useIsSmallerThanBreakpointWidth.ts

import {useState, useEffect} from 'react';
import type {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';

/**
 * A gerenralised custom hook to which return true if the screensize becomes lesser than the provided breakpoint
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export const useIsSmallerThanBreakpointWidth = (breakpointWidth: SCREEN_BREAKPOINTS): boolean => {
  const [isSmaller, setIsSmaller] = useState(window.innerWidth < parseInt(breakpointWidth, 10));

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmaller(window.innerWidth <= parseInt(breakpointWidth, 10));
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpointWidth]);

  return isSmaller;
};
