import type {Dispatch, ReactElement, SetStateAction} from 'react';
import React from 'react';
import {PanelHeader} from '@Components/panel';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {LOADING_SCREEN_SHOW_TIME} from '@Panels/email-metrics-download-pdf-panel/email-metrics-download-pdf-panel.types';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import {isUserPremiumPlus} from '@Libraries/user.library';
import {doesUserHaveBrands} from '@Libraries/brands-library';
import styles from './download-pdf-report-header.module.scss';

interface DownloadPDFReportHeaderProps {
  isInModal: boolean;
  onClose: () => void;
  setInSelectMode: Dispatch<SetStateAction<boolean>>;
  inSelectMode: boolean;
  setShowOpeningBrandKitsLoader: Dispatch<SetStateAction<boolean>>;
  loadingScreenBeingShown: boolean;
  isReportPreviewOpenInMobileMode: boolean;
  setIsReportPreviewOpenInMobileMode: Dispatch<SetStateAction<boolean>>;
}

/**
 * The panel header in the PDF download wizard when the campaign metrics 'download' option is clicked
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function DownloadPDFReportHeader({isInModal = true, onClose, ...props}: DownloadPDFReportHeaderProps): ReactElement {
  const className = `${styles.container} _no-border-bottom flex-row-justify-between flex-items-center spacing-p-l-5 spacing-p-r-2`;
  const isMobile = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.TABLET);
  const onBack = (): void => {
    if (isMobile && !props.isReportPreviewOpenInMobileMode && (!isUserPremiumPlus() || !doesUserHaveBrands())) {
      props.setIsReportPreviewOpenInMobileMode(true);
      return;
    }

    props.setShowOpeningBrandKitsLoader(true);
    props.setInSelectMode(true);
    setTimeout(() => {
      props.setShowOpeningBrandKitsLoader(false);
    }, LOADING_SCREEN_SHOW_TIME.FROM_EDIT_MODE_TO_SELECT_MODE);
  };

  const getPanelHeaderText = (): string => {
    if (props.loadingScreenBeingShown) {
      return '';
    }

    if (props.inSelectMode) {
      if (props.isReportPreviewOpenInMobileMode) {
        return window.i18next.t('pmwjs_download_report');
      }

      return window.i18next.t('pmwjs_customize_report');
    }

    return window.i18next.t('pmwjs_brand_kits');
  };

  const shouldShowBackButton = (): boolean => {
    if (isMobile && props.isReportPreviewOpenInMobileMode) {
      return false;
    }

    if (isMobile && !props.isReportPreviewOpenInMobileMode) {
      if (!isUserPremiumPlus() || !doesUserHaveBrands()) {
        return true;
      }

      return !props.inSelectMode && !props.loadingScreenBeingShown;
    }

    return !props.inSelectMode && !props.loadingScreenBeingShown;
  };

  const panelHeaderForPanelOutsideOfAModal = (): ReactElement => {
    return (
      <div className={className}>
        <Text val={window.i18next.t('pmwjs_download_report')} className="content-heading" size={TextSize.MEDIUM} bold />
        <Icon icon="icon-close" className="content-body" type={IconType.GHOST_UNCOLORED} shape={IconShape.SQUARE} onClick={onClose} size={IconSize.SIZE_ICON_20} />
      </div>
    );
  };

  if (!isInModal) return panelHeaderForPanelOutsideOfAModal();

  return <PanelHeader isOpenedInModal showBackButton={shouldShowBackButton()} onBackButton={onBack} title={getPanelHeaderText()} onClose={onClose} centerAlignTitle={isMobile} />;
}
