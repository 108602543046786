import type {ReactElement, ReactNode} from 'react';
import React, { useState} from 'react';
import type { PopoverProps} from '@Components/popover';
import {Popover} from '@Components/popover';
import type {CustomPopoverActions, PopoverActions} from '@Components/popover/popover.types';
import {useIsMounted} from '@Hooks/useIsMounted';

export function usePopover(props: Omit<PopoverProps, 'popoverCloseFunction' | 'children'>, customActions: CustomPopoverActions = {}): PopoverActions {
  const shouldUseCustomActions = typeof customActions?.isPopoverOpen !== 'undefined' && typeof customActions?.closePopover !== 'undefined';
  const [isPopoverOpen, setPopoverOpen] = useState(customActions?.isPopoverOpen ?? false);
  const isMounted = useIsMounted();

  const toggle = (): void => {
    setPopoverOpen((val) => {
      return !val;
    });
  };

  const close = (): void => {
    setPopoverOpen(false);
  };

  const open = (): void => {
    setPopoverOpen(true);
  };

  const noop = (): void => {};

  customActions.togglePopover = customActions.togglePopover ?? noop;
  customActions.openPopover = customActions.openPopover ?? noop;
  customActions.isPopoverOpen = customActions.isPopoverOpen ?? false;

  const isOpenState = shouldUseCustomActions ? customActions.isPopoverOpen : isPopoverOpen;
  const closePopover = (): void => {
    if (!isMounted) {
      return;
    }

    customActions.closePopover = customActions.closePopover ?? noop;
    if (shouldUseCustomActions) {
      customActions.closePopover();
    } else {
      close();
    }
  };

  const renderPopover = (children: ReactNode): ReactElement | null => {
    return isOpenState ? (
      <Popover {...props} popoverCloseFunction={closePopover}>
        {children}
      </Popover>
    ) : null;
  };

  return {
    isPopoverOpen: isOpenState,
    closePopover,
    togglePopover: shouldUseCustomActions ? customActions.togglePopover : toggle,
    openPopover: shouldUseCustomActions ? customActions.openPopover : open,
    renderPopover,
  };
}
