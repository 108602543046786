import type {ReactElement} from 'react';
import React, { useState} from 'react';
import {ColorPickerPopup} from '@Components/color-picker-popup';
import {ColorFill} from '@Components/color-fill';
import {noop} from '@Utils/general.util';
import {Dropdown} from '@Components/dropdown-v2';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from '@Components/dropdown-v2/dropdown.types';
import styles from './color-picker-box.module.scss';

interface ColorPickerBoxProps {
  dropdownClassName?: string;
  popUpClassName?: string;
  colorFillClassName?: string;
  color?: string;

  onChange?(color: string): void;

  onChangeEnded?(color: string): void;
}

export function ColorPickerBox({
  onChange = noop,
  onChangeEnded = noop,
  colorFillClassName = '',
  popUpClassName = '',
  dropdownClassName = '',
  color = '#FFFFFF',
}: ColorPickerBoxProps): ReactElement | null {
  const [currentColor, setCurrentColor] = useState(color);
  const [showColorPickerPopup, setShowColorPickerPopup] = useState(false);

  return (
    <Dropdown
      selector={
        <ColorFill
          color={currentColor}
          className={colorFillClassName}
          onClick={(): void => {
            setShowColorPickerPopup(!showColorPickerPopup);
          }}
        />
      }
      popup={
        <ColorPickerPopup
          color={currentColor}
          onChange={(e): void => {
            setCurrentColor(e);
            onChange(e);
          }}
          onChangeEnded={(e): void => {
            setCurrentColor(e);
            onChangeEnded(e);
          }}
        />
      }
      className={dropdownClassName}
      popupClassName={`${styles.dropdownContent} ${popUpClassName}`}
      alignment={ALIGNMENT_TYPE.BOTTOM_START}
      position={DROPDOWN_POSITION.ABSOLUTE}
      doCloseOnInnerClick={false}
    />
  );
}
