import type {ReactElement, ReactNode} from 'react';
import React, { useRef, useState} from 'react';
import {useCustomEventFormData, useCustomEventFormSetters} from '@Components/content-planner/content-planner.hooks';
import {getDateFromUnixTimestamp} from '@Utils/date.util';
import {usePopover} from '@Components/popover/hooks/usePopover';
import {CalendarDateTimePicker} from '@Components/calendar-date-time-picker';
import {INPUT_FIELD_TYPE, InputField} from '@Components/input-field';
import {ContentPlannerDateTimeTile} from '@Components/content-planner-date-time-tile';
import {updateContentPlannerDateIfNeeded} from '@Components/content-planner/content-planner-reducer';
import type {CustomEventFormDateTileProps} from '@Components/content-planner-custom-event-form/content-planner-custom-event-form.types';
import { EventInputMargin, FormActivePanel} from '@Components/content-planner-custom-event-form/content-planner-custom-event-form.types';
import helper from '@Components/content-planner/content-planner-util.module.scss';
import {MAX_EVENT_DESCRIPTION_LENGTH} from '@Libraries/content-planner-library';
import styles from './content-planner-custom-event-form.module.scss';
import {useAppDispatch} from '@/hooks';

interface ContentPlannerCustomEventFormProps extends CustomEventFormDateTileProps {
  children: ReactNode;
  className?: string;
  descriptionFieldHasFluidHeight?: boolean;
  animateIn?: boolean;
  descriptionInputMargin?: EventInputMargin;
}

export function ContentPlannerCustomEventForm({
  children,
  openDatePickerInPanel = false,
  showDatePickerEditIcon = true,
  showDateTileAtEnd = false,
  dateTileBtnSpacingClasses = '',
  smallTextSizeInDateTile = false,
  changeDateOnDatePickerChange = true,
  className = '',
  descriptionFieldHasFluidHeight = false,
  animateIn = false,
  descriptionInputMargin = EventInputMargin.TOP_16,
}: ContentPlannerCustomEventFormProps): ReactElement {
  const formData = useCustomEventFormData();
  const date = getDateFromUnixTimestamp(formData.timestamp);
  const {onTitleChange, onDescriptionChange, onDateChange} = useCustomEventFormSetters();
  const ref = useRef<HTMLDivElement>(null);
  const {closePopover, togglePopover, renderPopover} = usePopover({referenceElementRef: ref});
  const dispatch = useAppDispatch();
  const [activePanel, setActivePanel] = useState<FormActivePanel>(FormActivePanel.DEFAULT);
  const isDatePickerPanelActive = openDatePickerInPanel && activePanel === FormActivePanel.DATEPICKER;

  const handleDatePickerClose = (): void => {
    if (openDatePickerInPanel) {
      setActivePanel(FormActivePanel.DEFAULT);
    } else {
      closePopover();
    }
  };

  const toggleDatepickerOpen = (): void => {
    if (openDatePickerInPanel) {
      setActivePanel(FormActivePanel.DATEPICKER);
    } else {
      togglePopover();
    }
  };

  const onDatepickerSubmit = (newDate: Date): void => {
    if (changeDateOnDatePickerChange) {
      dispatch(updateContentPlannerDateIfNeeded(newDate));
    }
    onDateChange(newDate);
    handleDatePickerClose();
  };

  const renderForm = (): ReactElement => {
    return (
      <>
        <ContentPlannerDateTimeTile
          className={showDateTileAtEnd ? `${styles.showAtEnd} spacing-m-t-5` : 'spacing-m-b-5'}
          btnSpacingClassName={dateTileBtnSpacingClasses}
          date={date}
          onClick={toggleDatepickerOpen}
          ref={ref}
          showEditIcon={showDatePickerEditIcon}
          showHover={!openDatePickerInPanel}
          showExtraSmallText={smallTextSizeInDateTile}
        />
        {renderPopover(<CalendarDateTimePicker prefilledDate={date} onSubmit={onDatepickerSubmit} timePickerCtaText={window.i18next.t('pmwjs_save')} showBoxShadow={false} />)}

        <InputField
          onInputChange={onTitleChange}
          placeholder={window.i18next.t('pmwjs_title')}
          className={`${styles.title} radius-4 -darker-border`}
          inputClassName="spacing-p-l-3 spacing-p-r-3 __full-width"
          containerClassName={styles.fixedInput}
          showInputIcon={false}
          value={formData.title}
        />

        <InputField
          onInputChange={onDescriptionChange}
          placeholder={window.i18next.t('pmwjs_description')}
          className={`${!descriptionFieldHasFluidHeight ? styles.description : ''} radius-4 -darker-border`}
          inputClassName={`spacing-p-l-3 spacing-p-r-3 spacing-p-t-2 spacing-p-b-2 ${styles.descriptionInput}`}
          containerClassName={`${descriptionInputMargin} ${!descriptionFieldHasFluidHeight ? styles.fixedInput : ''}`}
          type={INPUT_FIELD_TYPE.TEXT}
          showInputIcon={false}
          value={formData.description}
          maxLength={MAX_EVENT_DESCRIPTION_LENGTH}
          isMultiLine
        />

        {children}
      </>
    );
  };

  return (
    <div className={`flex-v-row ${styles.container} ${animateIn ? helper.animated : ''} ${className}`}>
      {isDatePickerPanelActive ? (
        <CalendarDateTimePicker
          className={styles.negativeMarginTop}
          prefilledDate={date}
          onSubmit={onDatepickerSubmit}
          timePickerCtaText={window.i18next.t('pmwjs_save')}
          showBoxShadow={false}
          isFullWidth
        />
      ) : (
        renderForm()
      )}
    </div>
  );
}
