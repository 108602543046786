import type {Dispatch, ReactElement, SetStateAction} from 'react';
import React from 'react';
import {PanelFooter, PanelFooterPrimaryDefaultAction, PanelFooterSecondaryDefaultAction} from '@Components/panel';
import {IconPlacement, Size, Type} from '@Components/button';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import {isUserPremiumPlus} from '@Libraries/user.library';
import {doesUserHaveBrands} from '@Libraries/brands-library';
import styles from './download-pdf-report-footer.module.scss';

interface DownloadPDFReportFooterProps {
  isReportPreviewOpenInMobileMode: boolean;
  setIsReportPreviewOpenInMobileMode: Dispatch<SetStateAction<boolean>>;
  loadingPDFRender: boolean;
  onClickDownloadReport: VoidFunction;
}
/**
 * The panel footer in the PDF download wizard when the campaign metrics 'download' option is clicked
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function DownloadPDFReportFooter({...props}: DownloadPDFReportFooterProps): ReactElement {
  const isMobile = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.TABLET);

  const triggerDownload = (): void => {
    props.onClickDownloadReport();
  };

  const onPreviewClick = (): void => {
    props.setIsReportPreviewOpenInMobileMode(true);
  };

  const getPrimaryActions = (): ReactElement[] | null => {
    if (props.isReportPreviewOpenInMobileMode) {
      return [
        <PanelFooterPrimaryDefaultAction
          key="pmw-download-email-campaign-pdf"
          onClick={triggerDownload}
          isLoading={props.loadingPDFRender}
          size={Size.SMALL}
          text={window.i18next.t('pmwjs_download_report')}
          icon="icon-download"
          iconPlacement={IconPlacement.RIGHT}
          isFullWidth={isMobile}
        />,
      ];
    }

    if (isUserPremiumPlus() && doesUserHaveBrands()) {
      return [
        <PanelFooterSecondaryDefaultAction
          key="pmw-preview-email-campaign-pdf"
          onClick={onPreviewClick}
          size={Size.SMALL}
          text={window.i18next.t('pmwjs_preview_report')}
          isFullWidth
          className={styles.adjustTopMarginForPrimaryActionGapOnMobile}
        />,
      ];
    }

    return null;
  };

  const getCustomizeReportIcon = (): NonNullable<object> => {
    if (!isUserPremiumPlus()) {
      return {icon: 'icon-crown', iconClassName: 'color-premium'};
    }

    return {icon: 'icon-brands'};
  };

  const getSecondaryActions = (): [ReactElement] | null => {
    if (!isMobile) {
      return null;
    }

    if (props.isReportPreviewOpenInMobileMode) {
      return [
        <PanelFooterSecondaryDefaultAction
          key="customize-pdf-report-btn"
          type={Type.SECONDARY}
          text={`${window.i18next.t('pmwjs_customize_report')}`}
          size={Size.SMALL}
          iconPlacement={IconPlacement.LEFT}
          onClick={(): void => {
            props.setIsReportPreviewOpenInMobileMode(false);
          }}
          isFullWidth={isMobile}
          {...getCustomizeReportIcon()}
        />,
      ];
    }

    if (!isUserPremiumPlus()) {
      return [
        <PanelFooterSecondaryDefaultAction
          key="upsell-btn"
          iconPlacement={IconPlacement.LEFT}
          iconClassName="color-premium"
          icon="icon-crown"
          type={Type.SECONDARY}
          size={Size.MEDIUM}
          text={window.i18next.t('pmwjs_common_upgrade_to_premium')}
          href={window.PMW.util.site_url('premium')}
          isFullWidth={isMobile}
        />,
      ];
    }

    if (!doesUserHaveBrands()) {
      return [
        <PanelFooterSecondaryDefaultAction
          key="try-brand-kits-btn"
          iconPlacement={IconPlacement.RIGHT}
          icon="icon-external-link"
          type={Type.SECONDARY}
          size={Size.MEDIUM}
          text={window.i18next.t('pmwjs_try_brand_kits')}
          isFullWidth={isMobile}
          onClick={(): void => {
            window.location.href = window.PMW.util.site_url('posters/mine#/brands');
          }}
        />,
      ];
    }

    return null;
  };

  return (
    <PanelFooter
      hasFullWidthActions={isMobile}
      border
      primaryActions={getPrimaryActions() ?? undefined}
      secondaryActions={getSecondaryActions() ?? undefined}
      spacingBetweenActionsClassname="spacing-m-t-2"
      className="spacing-p-1"
    />
  );
}
