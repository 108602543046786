import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {ResizeOption} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import {Text, TextSize} from '@Components/text';
import {SkeletonBrick} from '@Components/skeleton-brick';
import styles from './social-media-resize-options-preview.module.scss';
import {ClickableDiv} from '@/components/clickable-div';

interface SocialMediaResizePreviewProps {
  previewResizeType: ResizeOption;
  selected?: boolean;
  onDesignClick?: (e: ResizeOption) => void;
  imageSource: string;
  isVideo?: boolean;
  fillColor?: string;
  isLoading?: boolean;
}

export function SocialMediaResizeOptionsPreview({
  selected = false,
  previewResizeType = ResizeOption.FILL,
  fillColor = '#000000',
  isLoading = false,
  ...props
}: SocialMediaResizePreviewProps): ReactElement | null {
  const [isPortrait, setIsPortrait] = useState(false);
  const PREVIEW_CONTAINER_WIDTH_PX = 80;
  const PREVIEW_CONTAINER_HEIGHT_PX = 80;

  const getTextForDesignPreview = (): string => {
    if (previewResizeType === ResizeOption.BLUR) {
      return window.i18next.t('pmwjs_blur');
    }
    if (previewResizeType === ResizeOption.STRETCH) {
      return window.i18next.t('pmwjs_stretch');
    }
    if (previewResizeType === ResizeOption.FILL) {
      return window.i18next.t('pmwjs_fill');
    }

    return window.i18next.t('pmwjs_crop');
  };

  const getResizeTypeClasses = (): string => {
    if (previewResizeType === ResizeOption.BLUR) {
      return styles.blur;
    }
    if (previewResizeType === ResizeOption.STRETCH) {
      return styles.stretch;
    }
    if (previewResizeType === ResizeOption.FILL) {
      return styles.fill;
    }
    return styles.crop;
  };

  const getClickableDivStyles = (): object => {
    if (previewResizeType === ResizeOption.FILL) {
      return {
        backgroundColor: fillColor,
      };
    }
    return {};
  };

  const getClassesForThumbPreviewImage = (): string => {
    let stylesToReturn = getResizeTypeClasses();
    stylesToReturn = `${stylesToReturn} ${isPortrait ? styles.internalImageContainer : ''}`;
    return stylesToReturn;
  };

  useEffect(() => {
    const image = new Image();
    image.src = props.imageSource;

    image.onload = (): void => {
      const aspectRatio = image.width / image.height;
      setIsPortrait(aspectRatio < 1);
    };
  }, [props.imageSource]);

  const getMediaItemForPreview = (): ReactElement => {
    if (previewResizeType === ResizeOption.BLUR) {
      return (
        <div className={styles.blurredImageContainer}>
          {isLoading ? (
            <SkeletonBrick className="_unmargin" width={PREVIEW_CONTAINER_WIDTH_PX} height={PREVIEW_CONTAINER_HEIGHT_PX} />
          ) : (
            <img src={props.imageSource} alt="Unable to load" className={getClassesForThumbPreviewImage()} />
          )}
          <div className={styles.blurredImage} style={{background: `url(${props.imageSource})`}} />
        </div>
      );
    }

    if (isLoading) {
      return <SkeletonBrick className="_unmargin" width={PREVIEW_CONTAINER_WIDTH_PX} height={PREVIEW_CONTAINER_HEIGHT_PX} />;
    }

    return <img src={props.imageSource} alt="Unable to load" className={getClassesForThumbPreviewImage()} />;
  };

  const getPostPreview = (): ReactElement => {
    return (
      <ClickableDiv
        onClick={(): void => {
          if (!props.onDesignClick) {
            return;
          }

          props.onDesignClick(previewResizeType);
        }}
        className={`${getResizeTypeClasses()} ${styles.socialPostPreview} ${styles.imageContainer} ${selected ? styles.selected : ''}`}
        style={getClickableDivStyles()}
      >
        {getMediaItemForPreview()}
      </ClickableDiv>
    );
  };

  return (
    <div className={styles.eachPreviewContainer}>
      {getPostPreview()}
      <Text val={getTextForDesignPreview()} bold={selected} size={TextSize.SMALL} className={styles.eachPreviewContainerText} />
    </div>
  );
}
