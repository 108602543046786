import type {Dispatch, MutableRefObject, ReactElement, SetStateAction} from 'react';
import React, {useEffect} from 'react';
import {executeThunk} from '@Utils/thunk.util';
import {connect, useDispatch} from 'react-redux';
import {BrandColorsSection} from '@Components/mystuff-brands/components/brand-colors-section';
import {areBrandsInEmptyState, areBrandsLoading, doesUserHaveBrands} from '@Libraries/brands-library';
import {getBrandsAndActiveBrandsForUser} from '@Components/mystuff-brands/brands-thunk';
import {CircularProgressLoader, LOADER_SIZE} from '@Components/circular-progress-loader';
import {noop} from 'lodash';
import {BrandLogosSection} from '@Components/mystuff-brands/components/brand-logos-section';
import type {AnyAction} from 'redux';
import './download-pdf-report-content.scss';
import type {ActiveBrandId, Brand, BrandColor, BrandLogo, BrandsLoadingState} from '@Components/mystuff-brands/brand-kits.types';
import {SELECTABLE_COLOR_EMPTY} from '@Components/mystuff-brands/brand-kits.types';
import {DownloadPDFReportPreview} from '@Panels/email-metrics-download-pdf-panel/components/download-pdf-report-preview';
import {isUserPremiumPlus} from '@Libraries/user.library';
import {getAssetUrl} from '@Utils/s3.util';
import {ControlledList} from '@Components/controlled-list';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {THUMBNAIL_TYPE} from '@Components/thumbnail';
import type {ControlledListItem2} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {Button, IconPlacement, Size, Type} from '@Components/button';
import {BrandFontsSection} from '@Components/mystuff-brands/components/brand-fonts-section';
import {Text, TextSize} from '@Components/text';
import {BrandAssetsProgressAndIllustration} from '@Components/mystuff-brands/components/brand-assets-progress-and-illustration';

import {TabItems, Tabs, TabSliderType, TabsThemes} from '@Components/tabs';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import {setSelectedColorsInSelectableState, setSelectedLogoInSelectableState} from '@Components/mystuff-brands/brands-reducer';
import {DownloadPDFReportBrandSelectorDropdown} from '@Panels/email-metrics-download-pdf-panel/components/download-pdf-report-brand-selector-dropdown';
import {LOADING_SCREEN_SHOW_TIME} from '@Panels/email-metrics-download-pdf-panel/email-metrics-download-pdf-panel.types';
import type {RootState} from '@/store';

interface DownloadPDFReportContentProps {
  isInModal?: boolean;
  onClose?: () => void;
  brandsLoadingState: BrandsLoadingState;
  isInEmptyState: boolean;
  showNewBrandMobileWizard: boolean;
  brands: Brand[];
  activeBrandId?: ActiveBrandId;
  setInSelectMode: Dispatch<SetStateAction<boolean>>;
  inSelectMode: boolean;
  showOpeningBrandKitsModal: boolean;
  setShowOpeningBrandKitsLoader: Dispatch<SetStateAction<boolean>>;
  isReportPreviewOpenInMobileMode: boolean;
  pdfRef: MutableRefObject<any>;
  brandLogos: Record<string, BrandLogo>;
  brandColors: Record<string, BrandColor>;
}

/**
 * The panel content section in the PDF download wizard when the campaign metrics 'download' option is clicked
 * It shows either the brands kit or the upsell screen or the
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function DownloadPDFReportContent({
  isInModal,
  onClose,
  setInSelectMode,
  inSelectMode,
  showOpeningBrandKitsModal,
  setShowOpeningBrandKitsLoader,
  ...props
}: DownloadPDFReportContentProps): ReactElement {
  const dispatchReducer = useDispatch();
  const isMobileScreen = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.TABLET);

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    if (props.activeBrandId && props.brands.length && props.brandColors && props.brandLogos) {
      makeDefaultColorSelections();
      makeDefaultLogoSelections();
    }
  }, [props.activeBrandId, props.brands, props.brandColors, props.brandLogos]);

  const fetchBrands = (): void => {
    void executeThunk(() => {
      dispatchReducer(getBrandsAndActiveBrandsForUser() as unknown as AnyAction);
    })
      .then(noop)
      .catch(noop);
  };

  const onBrandPreview = (): void => {
    window.PMW.openBrandPreviewModal({brandId: props.activeBrandId});
  };

  const makeDefaultColorSelections = (): void => {
    const allColors = Object.values(props.brandColors);
    const colorsForThisbrand = allColors.filter((brandColor) => {
      return brandColor.brandId === props.activeBrandId;
    });

    if (colorsForThisbrand.length < 1) {
      dispatchReducer(
        setSelectedColorsInSelectableState({
          defaultSelected: true,
        })
      );

      return;
    }

    const first3ColorIds = colorsForThisbrand
      .map((color) => {
        return color.idbrandColor;
      })
      .slice(0, 3);

    dispatchReducer(
      setSelectedColorsInSelectableState({
        defaultSelected: false,
        ids: {
          Primary: first3ColorIds[0] || SELECTABLE_COLOR_EMPTY,
          Secondary: first3ColorIds[1] || SELECTABLE_COLOR_EMPTY,
          Tertiary: first3ColorIds[2] || SELECTABLE_COLOR_EMPTY,
        },
      })
    );
  };

  const makeDefaultLogoSelections = (): void => {
    const allLogos = Object.values(props.brandLogos);
    const logosForThisBrand = allLogos.filter((brandLogo) => {
      return brandLogo.brandId === props.activeBrandId;
    });

    if (logosForThisBrand.length < 1) {
      dispatchReducer(
        setSelectedLogoInSelectableState({
          defaultSelected: true,
          ids: [],
        })
      );

      return;
    }

    dispatchReducer(
      setSelectedLogoInSelectableState({
        defaultSelected: false,
        id: Object.values(props.brandLogos).find((logo) => {
          return logo.brandId === props.activeBrandId;
        })?.idbrandImage,
      })
    );
  };

  const openBrandKits = (): void => {
    setShowOpeningBrandKitsLoader(true);
    setInSelectMode(false);
    setTimeout(() => {
      setShowOpeningBrandKitsLoader(false);
    }, LOADING_SCREEN_SHOW_TIME.FROM_SELECT_MODE_TO_EDIT_MODE);
  };

  const getContentBasedOnUserPremiumLevel = (): ReactElement => {
    if (isUserPremiumPlus() && doesUserHaveBrands()) {
      return getBrandKitsModule();
    }

    return getFallbackContent();
  };

  const getPreviewBasedOnBrandKitsInSelectableMode = (): ReactElement => {
    if (inSelectMode) {
      return getPreviewAndPrintablePreview();
    }

    return getBrandsIllustrationForEditableBrandKits();
  };

  const getBrandKitsModule = (): ReactElement => {
    if (isMobileScreen) {
      return getTabbedBrandKitsForMobile();
    }

    return getBrandsKit();
  };

  const getAppropriateButtonBasedOnFallbackReason = (): ReactElement | null => {
    if (isMobileScreen) {
      return null;
    }

    if (doesUserHaveBrands()) {
      return getUpgradeToPremiumPlusButton();
    }

    return getTryBrandKitsButton();
  };

  const getFallbackContentIconSize = (): IconSize => {
    if (isMobileScreen) {
      return IconSize.SIZE_ICON_16;
    }

    return IconSize.SIZE_ICON_24;
  };

  const onClickUpgradeToPremiumPlus = (): void => {
    if (onClose) onClose();
    window.location.href = window.PMW.util.site_url('premium');
  };

  const onClickTryBrandsKit = (): void => {
    if (onClose) onClose();
    window.location.href = window.PMW.util.site_url('posters/mine#/brands');
  };

  const getPreviewAndPrintablePreview = (): ReactElement => {
    return (
      <div className="preview-container light-background">
        <DownloadPDFReportPreview />
        <div className="printable-preview-copy -hidden">
          <DownloadPDFReportPreview pdfRef={props.pdfRef} isPrintable />
        </div>
      </div>
    );
  };

  const getBrandsIllustrationForEditableBrandKits = (): ReactElement => {
    return (
      <div className="preview-container -content-without-footer">
        <BrandAssetsProgressAndIllustration />
      </div>
    );
  };

  const getTabbedBrandKitsForMobile = (): ReactElement => {
    const selectableInputProps = {
      ...(inSelectMode && {
        isReadOnly: true,
        isSelectable: true,
      }),
    };

    const tabs: TabItems = [
      {
        tabKey: 'logos',
        className: '_unpadded-left _unpadded-right',
        textClassName: 'body-xs-bold',
        text: window.i18next.t('pmwjs_logos'),
      },
      {
        tabKey: 'colors',
        className: '_unpadded-left _unpadded-right',
        textClassName: 'body-xs-bold',
        text: window.i18next.t('pmwjs_colors'),
      },
    ];

    if (!inSelectMode) {
      tabs.push({
        tabKey: 'fonts',
        text: window.i18next.t('pmwjs_fonts'),
      });
    }

    return (
      <div className="brand-assets-container">
        <DownloadPDFReportBrandSelectorDropdown brands={props.brands} />
        <Tabs
          className="flex-content-center spacing-m-b-5"
          id="pdf-preview-content-mobile"
          selectedTab="logos"
          sliderType={TabSliderType.SLIDER_LINE}
          tabItems={tabs}
          tabsContent={{
            logos: <BrandLogosSection {...selectableInputProps} sectionHeading={window.i18next.t('pmwjs_logos')} onAddLogo={openBrandKits} showAddButtonOutsideGrid hideHeading />,
            colors: (
              <BrandColorsSection {...selectableInputProps} sectionHeading={window.i18next.t('pmwjs_colors')} onAddColors={openBrandKits} showAddButtonOutsideGrid hideHeading />
            ),
            // eslint-disable-next-line react/jsx-no-useless-fragment
            fonts: !inSelectMode ? <BrandFontsSection sectionHeading={window.i18next.t('pmwjs_fonts')} showAddButtonOutsideGrid hideHeading /> : <></>,
          }}
          theme={TabsThemes.DEFAULT}
        />
      </div>
    );
  };

  const getBrandsKit = (): ReactElement => {
    const selectableInputProps = {
      ...(inSelectMode && {
        isReadOnly: true,
        isSelectable: true,
      }),
    };

    return (
      <div className={`brand-assets-container ${!inSelectMode ? '-content-without-footer' : ''}`}>
        <DownloadPDFReportBrandSelectorDropdown brands={props.brands} />
        <BrandLogosSection {...selectableInputProps} sectionHeading={window.i18next.t('pmwjs_logos')} onAddLogo={openBrandKits} />
        <BrandColorsSection {...selectableInputProps} sectionHeading={window.i18next.t('pmwjs_colors')} onAddColors={openBrandKits} />
        {!inSelectMode ? <BrandFontsSection sectionHeading={window.i18next.t('pmwjs_fonts')} /> : null}
      </div>
    );
  };

  const getUpgradeToPremiumPlusButton = (): ReactElement => {
    return (
      <Button
        iconPlacement={IconPlacement.LEFT}
        iconClassName="color-premium"
        icon="icon-crown"
        type={Type.SECONDARY}
        size={Size.MEDIUM}
        text={window.i18next.t('pmwjs_common_upgrade_to_premium')}
        onClick={onClickUpgradeToPremiumPlus}
      />
    );
  };

  const getTryBrandKitsButton = (): ReactElement => {
    return (
      <Button
        iconPlacement={IconPlacement.RIGHT}
        icon="icon-external-link"
        type={Type.SECONDARY}
        size={Size.MEDIUM}
        text={window.i18next.t('pmwjs_try_brand_kits')}
        onClick={onClickTryBrandsKit}
      />
    );
  };

  const getFallbackContent = (): ReactElement => {
    return (
      <div className="brand-assets-container non-premium-user-report-customization">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video autoPlay muted loop crossOrigin="anonymous" className="fallback-video">
          <source type="video/mp4" src={getAssetUrl('email-campaign-metrics-pdf-export-wizard-premium-fixed.mp4')} />
        </video>
        <div className="content-container">
          <h4 className="non-premium-upsell-heading spacing-p-1">{window.i18next.t('pmwjs_customize_reports_with_brand_kits')}</h4>
          <ControlledList
            id="controlled-type-2"
            items={[
              {
                id: 'controlled-list-item-2',
                subText: '',
                text: window.i18next.t('pmwjs_add_your_brand_logos_and_colors_to_your_report'),
                thumbnail: {
                  icon: 'icon-logo',
                  IconShape: IconShape.CIRCLE,
                  icon_size: getFallbackContentIconSize(),
                  icon_type: IconType.FLAT,
                  type: THUMBNAIL_TYPE.ICON,
                },
                type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
                isDisabled: true,
                className: 'fallback-content-item',
              } as ControlledListItem2,
              {
                id: 'controlled-list-item-2',
                subText: '',
                text: window.i18next.t('pmwjs_easily_access_your_brand_assets_in_the_editor'),
                thumbnail: {
                  icon: 'icon-pencil-line',
                  IconShape: IconShape.CIRCLE,
                  icon_size: getFallbackContentIconSize(),
                  icon_type: IconType.FLAT,
                  type: THUMBNAIL_TYPE.ICON,
                },
                type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
                isDisabled: true,
                className: 'fallback-content-item',
              } as ControlledListItem2,
              {
                id: 'controlled-list-item-2',
                subText: '',
                text: window.i18next.t('pmwjs_brands_are_automatically_available_to_everyone_in_your_team'),
                thumbnail: {
                  icon: 'icon-facebook-group',
                  IconShape: IconShape.CIRCLE,
                  icon_size: getFallbackContentIconSize(),
                  icon_type: IconType.FLAT,
                  type: THUMBNAIL_TYPE.ICON,
                },
                type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
                isDisabled: true,
                className: 'fallback-content-item',
              } as ControlledListItem2,
            ]}
          />
        </div>
        <div className="non-premium-upsell-button-container">{getAppropriateButtonBasedOnFallbackReason()}</div>
      </div>
    );
  };

  if (areBrandsLoading(props.brandsLoadingState)) {
    return (
      <div className="pdf-wizard-loader-container -with-artificial-height">
        <div className="flex-v-row flex-column-align-center flex-column-justify-center -while-opening-brands">
          <CircularProgressLoader size={LOADER_SIZE.LARGE} classes="_d-block spacing-m-b-4 pdf-wizard-loader" />
        </div>
      </div>
    );
  }

  if (showOpeningBrandKitsModal) {
    return (
      <div className="pdf-wizard-loader-container -with-artificial-height">
        <div className="flex-v-row flex-column-align-center flex-column-justify-center -while-opening-brands">
          <CircularProgressLoader size={LOADER_SIZE.LARGE} classes="_d-block pdf-wizard-loader" />
          <div>
            <Text val={!inSelectMode ? window.i18next.t('pmwjs_opening_brand_kits_elipses') : window.i18next.t('pmwjs_closing_brand_kits_elipses')} size={TextSize.MEDIUM} bold />
          </div>
          <div>
            <Text
              val={!inSelectMode ? window.i18next.t('pmwjs_use_brand_kit_to_customize_report') : window.i18next.t('pmwjs_going_back_to_your_report_downloading_options')}
              size={TextSize.SMALL}
            />
          </div>
        </div>
      </div>
    );
  }

  if (isMobileScreen) {
    return (
      <div className="my-stuff-brands -for-email-campaign-metrics-pdf">
        {props.isReportPreviewOpenInMobileMode ? getPreviewBasedOnBrandKitsInSelectableMode() : getContentBasedOnUserPremiumLevel()}
      </div>
    );
  }

  return (
    <div className="my-stuff-brands -for-email-campaign-metrics-pdf">
      {getContentBasedOnUserPremiumLevel()}
      {getPreviewBasedOnBrandKitsInSelectableMode()}
    </div>
  );
}

const mapDownloadPDFReportContentStateToProps = (state: RootState) => {
  return {
    brandsLoadingState: state.brands.brandsLoadingState as BrandsLoadingState,
    isInEmptyState: areBrandsInEmptyState(state.brands.brands, state.brands.brandsLoadingState as BrandsLoadingState),
    showNewBrandMobileWizard: state.brands.showNewBrandMobileWizard,
    brands: state.brands.brands,
    activeBrandId: state.brands.activeBrandId,
    brandLogos: state.brands.logos,
    brandColors: state.brands.colors,
  };
};

export default connect(mapDownloadPDFReportContentStateToProps)(DownloadPDFReportContent);
