import type {ReactElement} from 'react';
import type {USER_PREMIUM_LEVELS, USER_TYPES} from '@Utils/user.util';
import type {VoidFunction} from '@Utils/general.util';

export enum SIDEBAR_LIST_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  MISC = 'misc',
}

export enum MYSTUFF_LEFT_SIDEBAR_PANEL {
  DEFAULT = 'default',
  DESIGNER_EARNINGS = 'earnings',
  MORE_OPTIONS = 'more-options',
}

export interface LeftSidebarBase {
  pageHash: string;
  userPremiumLevel: USER_PREMIUM_LEVELS;
  userType: USER_TYPES;
}

export interface LeftSidebarPanelContentBaseStorage {
  changeSidebarPanel: ChangeSidebarPanelHandler;
}

export type ChangeSidebarPanelHandler = (panel: MYSTUFF_LEFT_SIDEBAR_PANEL) => void;

export type SidebarMenuList = Array<ReactElement>;

export type SidebarListItemContent = {
  icon: string;
  text: string;
  url: string;
  isSelected: boolean;
  onClick?: VoidFunction;
  isNewPage?: boolean;
  className?: string;
  showPremiumIcon?: boolean;
  isLink?: boolean;
  dialogMode?: boolean;
  hasBorderAnimation?: boolean;
};
