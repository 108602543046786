import type {CustomEventPanelCallback, CustomEventsPanelInnerContentProps} from '@Components/content-planner/content-planner.types';

export enum EditPanelModes {
  DEFAULT = 'default',
  EDIT = 'editing',
  DELETE = 'delete',
}

export interface EditEventPanelInnerContentProps extends CustomEventsPanelInnerContentProps {
  isInEditMode: boolean;
  isInDeleteMode: boolean;
  switchToEditMode: CustomEventPanelCallback;
  switchToDeleteMode: CustomEventPanelCallback;
}
