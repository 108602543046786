export enum FormActivePanel {
  DEFAULT = 'default',
  DATEPICKER = 'datepicker',
}

export enum EventInputMargin {
  TOP_8 = 'spacing-m-t-2',
  TOP_16 = 'spacing-m-t-4',
}

export interface CustomEventFormDateTileProps {
  openDatePickerInPanel?: boolean;
  showDatePickerEditIcon?: boolean;
  showDateTileAtEnd?: boolean;
  dateTileBtnSpacingClasses?: string;
  smallTextSizeInDateTile?: boolean;
  changeDateOnDatePickerChange?: boolean;
}
